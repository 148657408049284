import React from "react";
import "./style.scss";
import Scroller from "../scroller/scroller";

const PageHeader = ({
  title1,
  title2,
  color,
  subtitle,
  bgImg,
  children,
  isMobile,
}) => {
  return (
    <div className="pageheader">
      {!isMobile && (
        <img src={bgImg} className="pageheader__image" alt="logo" />
      )}
      <div className={isMobile ? "header_content_mobile" : "header_content"}>
        <div className="header_title">
          <div className="ItemCard__description1">{title1}</div>
          <div
            className="ItemCard__description2"
            style={{
              color,
            }}
          >
            {title2}
          </div>
        </div>
        <div className="header_subtitle">{subtitle}</div>
        <hr
          className="title_page_separator"
          style={{
            borderColor: color,
          }}
        />
        {children}
        <Scroller/>
      </div>
    </div>
  );
};

export default PageHeader;
