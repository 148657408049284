import React, { useState, useEffect } from "react";
import "./style.scss";

const Scroller = () => {
  return (
    <div class="scroll-indicator">
      <div class="dots"></div>
    </div>
  );
};
export default Scroller;
