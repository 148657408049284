import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import ScrollToTop from "./utils/scrollToTop";
import "./index.css";
import Home from "./pages/home/home";
import CholesterolePage from "./pages/regards/cholesterol/cholesterolePage";
import RegimePage from "./pages/regards/regime/regimePage";
import MythePage from "./pages/regards/mythe/mythePage";
import PerfPage from "./pages/regards/perf/perfPage";
import IntoxPage from "./pages/regards/intox/intoxPage";
import EnnemiPage from "./pages/regards/ennemi/ennemiPage";
import * as serviceWorker from "./serviceWorker";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./i18n";

const Loader = () => <div>loading...</div>;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop/>
        <Switch>
          <Route exact path="/home">
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          </Route>
          <Route exact path="/cholesterol">
            <Suspense fallback={<Loader />}>
              <CholesterolePage />
            </Suspense>
          </Route>
          <Route exact path="/mythe">
            <Suspense fallback={<Loader />}>
              <MythePage />
            </Suspense>
          </Route>
          <Route exact path="/perf">
            <Suspense fallback={<Loader />}>
              <PerfPage />
            </Suspense>
          </Route>
          <Route exact path="/intox">
            <Suspense fallback={<Loader />}>
              <IntoxPage />
            </Suspense>
          </Route>
          <Route exact path="/regime">
            <Suspense fallback={<Loader />}>
              <RegimePage />
            </Suspense>
          </Route>
          <Route exact path="/ennemi">
            <Suspense fallback={<Loader />}>
              <EnnemiPage />
            </Suspense>
          </Route>
          <Route path="/">
            <Redirect to="/home">
              <Home />
            </Redirect>
          </Route>
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
