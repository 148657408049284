let fr = require("./fr.json");
let de = require("./de.json");

export const resources = {
  fr: {
    translation: fr,
  },
  de: {
    translation: de,
  },
};
