import React from "react";
import "./style.scss";
import { Row, Col } from "react-bootstrap";

const PageHeader = ({
  col1Txt,
  col2Txt,
  col3Txt,
  col1Title,
  col2Title,
  col3Title,
  color,
}) => {
  return (
    <div className="pageContent col_overhaul">
      <Row>
        <Col xs={12} md={4}>
          <div className="col_container">
            <div className="col_title">
              <div>{col1Title[0]}</div>
              <div
                style={{
                  color,
                  fontStyle: "italic",
                }}
              >
                {col1Title[1]}&#160;
              </div>
              <div>{col1Title[2]}</div>
            </div>
            <hr
              className="title_page_separator"
              style={{
                borderColor: color,
              }}
            />
            {col1Txt.map((txt) => (
              <div className="col_content page__content">{txt}</div>
            ))}
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="col_container">
            {" "}
            <div className="col_title">
              <div>{col2Title[0]}</div>
              <div
                style={{
                  color,
                  fontStyle: "italic",
                }}
              >
                {col2Title[1]}&#160;
              </div>
              <div>{col2Title[2]}</div>
            </div>
            <hr
              className="title_page_separator"
              style={{
                borderColor: color,
              }}
            />
            {col2Txt.map((txt) => (
              <div className="col_content page__content">{txt}</div>
            ))}
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="col_container">
            <div className="col_title">
              <div>{col3Title[0]}</div>
              <div
                style={{
                  color,
                  fontStyle: "italic",
                }}
              >
                {col3Title[1]}&#160;
              </div>
              <div>{col3Title[2]}</div>
            </div>
            <hr
              className="title_page_separator"
              style={{
                borderColor: color,
              }}
            />
            {col3Txt.map((txt) => (
              <div className="col_content page__content">{txt}</div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PageHeader;
