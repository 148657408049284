import React, { useState, useEffect } from "react";
// pages
import HeadaerHome from "./headerHome";
import CardingPage from "./carding_page";
import APropos from "./aPropos_page";
// navigation
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
// components
import { Parallax } from "react-parallax";
// style
import "../../App.scss";
// utile
import { debounce } from "../../utils/debounce";

const Home = () => {
  const [isMobile, setMobile] = useState(window.innerWidth <= 600 || window.innerHeight <= 650);
  const [offsetMenu, setoffsetMenu] = useState(25);

  const debouncedHandleResize = debounce(function handleResize() {
    setMobile(window.innerWidth <= 600 ||  window.innerHeight <= 650);
  }, 300);

  const debouncedHandleScroll = debounce(function handleScroll() {
    let offset = 25;
    const prestationsPage = document.getElementById("prestations");
    const aproposPage = document.getElementById("apropos");
    const contactPage = document.getElementById("contact");
    if (prestationsPage.getBoundingClientRect().top < 280) {
      offset += 150;
    }
    if (aproposPage.getBoundingClientRect().top < 280) {
      offset += 150;
    }
    if (contactPage.getBoundingClientRect().top < 280) {
      offset += 150;
    }
    setoffsetMenu(offset);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll, { passive: true });
    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  });

  return (
    <div className="App">
      {!isMobile && <Navbar offset={offsetMenu} />}
      <Parallax bgImage={require("../../images/pages/home.jpg")} strength={400}>
        <div id="home" className={isMobile ? "page__mobile" : "page"}>
          <HeadaerHome />
        </div>
      </Parallax>
      <div
        id="prestations"
        className={isMobile ? "page__mobile" : "page_prestation"}
      >
        <CardingPage />
      </div>

      <div id="apropos" className={isMobile ? "page__mobile" : ""}>
        {/**  <Parallax
          bgImage={require("../../images/pages/home.jpg")}
          strength={600}
        >*/}
          <div className={isMobile ? "page__mobile" : "page"}>
            <APropos />
          </div>
       {/**  </Parallax> */}
      </div>
      <div id="contact">
        
      </div>
      <Footer isMobile={isMobile}/>
    </div>
  );
};

export default Home;
