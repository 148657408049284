import React from "react";
import { useTranslation } from "react-i18next";
import "./banner.scss";

const APropos = () => {
  const { t } = useTranslation();
  return (
    <div className="banner">
      <div className="inner_banner">
        <div className="page_header">
          <hr className="page_header_separator" />
          <div>
            <div className="banner_row_text">{t("banner_line_1")}</div>
          </div>
          <div>
            <div className="banner_row_text">{t("banner_line_2")}</div>
          </div>
          <div>
            <div className="banner_row_text">
              <div>{t("banner_line_3_a")}</div>
              <div
                className="banner_txt_colored"
                style={{
                  fontStyle: "italic",
                }}
              >
                {t("banner_line_3_b")}
              </div>
              <div className="banner_txt_colored">
                {t("banner_line_3_c")}&#160;
              </div>
              <div>{t("banner_line_3_d")}</div>
            </div>
          </div>
          <div>
            <div className="banner_row_text">{t("banner_line_4")}</div>
          </div>
          <div>
            <div className="banner_row_text">{t("banner_line_5")}</div>
          </div>
          <div>
            <div className="banner_row_text">
              <div>{t("banner_line_6_a")}&#160;</div>
              <div className="banner_txt_colored">{t("banner_line_6_b")}</div>
              <div>&#160;{t("banner_line_6_c")}</div>
            </div>
          </div>
          <hr className="page_header_separator" />
        </div>
      </div>
    </div>
  );
};

export default APropos;
