import React from "react";

const HeaderHome = () => {
  return (
    <React.Fragment>
      <div className="home_page">
        {
          //<img src={logo} alt="logo" />
        }
        <div className="home_container">
          <div className="home_title">
            <div>LA</div>
          <div className="home_page__tuned">N</div>
          <div>UTRiTiON</div>
          </div>
          <hr className="title_separator" />
          <div className="home_subtitle">Votre première médecine</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderHome;
