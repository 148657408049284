import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { HashLink as Link } from "react-router-hash-link";
import { AiOutlineMail, AiFillLinkedin } from "react-icons/ai";
import "./navbar.scss";

const switchLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

const Navbar = ({ offset = 25 }) => {
  const [right, setright] = useState(offset);

  const handleMouseEnter = (e) => {
    setright(475 - e.target.offsetLeft);
  };

  const handleMouseLeave = (e) => {
    setright(offset);
  };

  useEffect(() => {
    setright(offset);
  }, [offset]);

  return (
    <React.Fragment>
      <div className="navbar_container">
        <div className="icon_navbar">
          <img src={require("../../images/Logo-N4L3.png")} alt="logo" />
        </div>
        <div className="navbar_whole">
          <ul className="navbar_menu">
            <div
              className="menuItem"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link smooth to="/home#contact" className="navbar_item">
                Contact
              </Link>
            </div>
            <div
              className="menuItem"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link smooth to="/home#apropos" className="navbar_item">
                À propos
              </Link>
            </div>
            <div
              className="menuItem"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link smooth to="/home#prestations" className="navbar_item">
                Regard
              </Link>
            </div>
            <div
              className="menuItem"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link smooth to="/home#home" className="navbar_item">
                Home
              </Link>
            </div>
            <div className="stripe" style={{ right: right }} />
          </ul>
        </div>
        {/*
        <div className="language_button">
          <img
            className="language_image"
            src={require("../../images/flags/fr.png")}
            onClick={() => switchLanguage("fr")}
          />
          <img
            className="language_image"
            src={require("../../images/flags/de.png")}
            onClick={() => switchLanguage("de")}
          />
        </div>
        */}
        <div className="bottom_navbar_container">
          <div className="bottom_navbar_content">
            <a href="mailto: info@nutrition-4-life.ch">
              <AiOutlineMail size={"1.5em"} className="navbar_clickable" />
            </a>
            <a href="https://www.linkedin.com/company/laurent-nutrition-4-life/" target="_blank">
              <AiFillLinkedin size={"1.5em"} className="navbar_clickable" />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
