import React, { useState, useEffect } from "react";
import "./style.scss";
//nav bar
import Navbar from "../../../components/navbar/navbar";
import PageHeader from "../../../components/pageComponents/header";
import ThreeColText from "../../../components/pageComponents/threeColText";
// utile
import { debounce } from "../../../utils/debounce";
import { useTranslation } from "react-i18next";

const MythePage = () => {
  const { t } = useTranslation();
  const [isMobile, setMobile] = useState(
    window.innerWidth <= 600 || window.innerHeight <= 650
  );

  const debouncedHandleResize = debounce(function handleResize() {
    setMobile(window.innerWidth <= 600 || window.innerHeight <= 650);
  }, 300);

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return (
    <div className="App">
      {!isMobile && <Navbar />}
      <div className={isMobile ? "page__mobile" : "page"}>
        <div className="App-header">
          <PageHeader
            title1={t("regime_title1")}
            title2={t("regime_title2")}
            subtitle={t("regime_subtitle")}
            color="#cc9966"
            bgImg={require("../../../images/pages/MontagneRusse.jpg")}
            isMobile={isMobile}
          >
            <div className="page__content">{t("regime_text_intro_1")}</div>
          </PageHeader>
        </div>
        <ThreeColText
          col1Txt={[t("regime_text_core_1")]}
          col2Txt={[t("regime_text_core_2a"),t("regime_text_core_2b")]}
          col3Txt={[t("regime_text_core_3a"),t("regime_text_core_3b")]}
          col1Title={[
            t("mythe_text_title_1a"),
            t("mythe_text_title_1b"),
            t("mythe_text_title_1c"),
          ]}
          col2Title={[
            t("mythe_text_title_2a"),
            t("mythe_text_title_2b"),
            t("mythe_text_title_2c"),
          ]}
          col3Title={[
            t("mythe_text_title_3a"),
            t("mythe_text_title_3b"),
            t("mythe_text_title_3c"),
          ]}
          color="#cc9966"
        />
      </div>
    </div>
  );
};

export default MythePage;
