import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./index.scss";

export default class ParallaxCard extends Component {
  movehandler(evt) {
    var bounds = evt.target.getBoundingClientRect();
    var x = evt.clientX - bounds.left;
    var y = evt.clientY - bounds.top;

    const halfW = this.refs.current.clientWidth / 2;
    const halfH = this.refs.current.clientHeight / 2;

    const coorX = halfW - (x - this.refs.current.offsetLeft);
    const coorY = halfH - (y - this.refs.current.offsetTop);

    this.setState({ degX: -(coorY / halfH) * 5 + "deg" });
    this.setState({ degY: (coorX / halfW) * 5 + "deg" });
    this.setState({ diplacmenentX: (coorX / halfH) * 5 + "px" });
    this.setState({ diplacmenentY: (coorY / halfW) * 5 + "px" });
  }

  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      degX: 0,
      degY: 0,
      diplacmenentX: 0,
      diplacmenentY: 0,
      topStyle: {
        top: 0,
        left: 0,
        position: "absolute",
        zIndex: 100,
        opacity: 0,
        transition: "opacity 0.2s",
      },
      topOffset: props.topOffset,
      imageBlur: { /*filter: `blur(0px)`,*/ transition: "0.2s" },
      backgroundImage: props.bgImg,
      topOpacity: 0,
      link: props.link,
    };

    this.movehandler = this.movehandler.bind(this);
  }

  leaveHandler() {
    this.setState({
      imageBlur: { /*filter: `blur(0px)`,*/ transition: "0.2s" },
    });
    this.setState({ degX: 0 });
    this.setState({ degY: 0 });
    this.setState({ diplacmenentX: 0 });
    this.setState({ diplacmenentY: 0 });
    this.setState({ topOpacity: 0 });
  }

  enterhandler() {
    this.setState({
      imageBlur: { /*filter: `blur(5px)`, */ transition: "0.2s" },
    });
    this.setState({ topOpacity: 1 });
  }
  render() {
    return (
      <React.Fragment>
        <div
          data-tilt
          id="current"
          ref="current"
          //className="animatedCard"
          style={{
            transform: `perspective( 1000px ) scale(1.1) rotateX(${this.state.degX}) rotateY(${this.state.degY})`,
            top: 40,
          }}
          className="ItemCard"
          onMouseMove={(evt) => this.movehandler(evt)}
          onMouseLeave={() => this.leaveHandler()}
          onMouseEnter={() => this.enterhandler()}
        >
          <Link smooth to={`/${this.props.link}`}>
            <figure className="ItemCard__thumb">
              <img
                style={this.state.imageBlur}
                src={this.state.backgroundImage}
              />
            </figure>
            <h2
              id="topTitle"
              ref="topTitle"
              className="ItemCard__titleContainer"
              style={{
                transform: `translate3d( ${this.state.diplacmenentX}, ${this.state.diplacmenentY}, 0 )`,
              }}
            >
              {this.props.title}
            </h2>
          </Link>
        </div>
        <div className="ItemCard__descriptionContainer">
          <div className="ItemCard__description">
            <div className="ItemCard__description1">{this.props.title1}</div>
            <div
              className="ItemCard__description2"
              style={{
                color: this.props.color,
              }}
            >
              {this.props.title2}
            </div>
          </div>
          <hr
            className="ItemCard__separator"
            style={{
              //background: this.props.color,
              background: `-webkit-gradient(radial, 50% 50%, 0, 50% 50%, 350, from(#000), ${this.props.color})`,
              //backgroundImage: `background-image: linear-gradient(to right, ${this.props.color}, #333, ${this.props.color});`
            }}
          />
          <div className="ItemCard__subdescription">
            {this.props.subdescription}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
