import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Footer = ({isMobile}) => {
  const { t } = useTranslation();
  return (
    <div className={isMobile ? "footer_mobile" : "footer"}>
      <div className="footer_block_2">
        <div className="footer_line">Laurent Kurer</div>
        <div className="footer_line">Nutritionniste MCO</div>
        <div className="footer_line">1053 Cugy</div>
        <div className="footer_line">info@nutrition-4-life.ch</div>
      </div>
      <div className="footer_block_2">
        <img
          className="footer_image"
          src={require("../../images/Logos/footer.png")}
          alt="logo"
        />
      </div>
      {!isMobile && <div className="footer_block"></div>}
    </div>
  );
};

export default Footer;
