import React from "react";
import { useTranslation } from "react-i18next";
import ParallaxCard from "../../components/card/card";
import { Row, Col, Container } from "react-bootstrap";
import "./index.scss";

const CardingPage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <div className="page_header">
          <hr className="page_header_separator" />
          <div>
            <div className="firstLine">
              <div className="firstLineA">{t("regard_header_text_a")}</div>
              <div className="firstLineB">{t("n_upper")}</div>
              <div className="firstLineC">{t("utrition_lower")}</div>
            </div>
          </div>
          <div>{t("regard_header_text_b")}</div>
          <div>{t("regard_header_text_c")}</div>
          <hr className="page_header_separator" />
        </div>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className="col__container">
            <ParallaxCard
              title1={t("mythe_rite_title1")}
              title2={t("mythe_rite_title2")}
              description={t("mythe_rite_desc")}
              subdescription={t("mythe_rite_subdesc")}
              bgImg={require("../../images/cards/Reforme.jpg")}
              color="#ffcc00"
              link="mythe"
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="col__container">
            <ParallaxCard
              title1={t("montagnes_russe_title1")}
              title2={t("montagnes_russe_title2")}
              description={t("montage_russes_desc")}
              subdescription={t("montage_russes_subdesc")}
              bgImg={require("../../images/cards/Regime.jpg")}
              color="#c68775"
              link="regime"
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="col__container">
            <ParallaxCard
              title1={t("perf_recup_title1")}
              title2={t("perf_recup_title2")}
              description={t("perf_recup_desc")}
              subdescription={t("perf_recup_subdesc")}
              bgImg={require("../../images/cards/sport.jpg")}
              color="#cc6666"
              link="perf"
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="col__container">
            <ParallaxCard
              title1={t("ami_ennemi_title1")}
              title2={t("ami_ennemi_title2")}
              description={t("ami_ennemi_desc")}
              subdescription={t("ami_ennemi_subdesc")}
              bgImg={require("../../images/cards/Sucre.jpg")}
              color="#66ccff"
              link="ennemi"
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="col__container">
            <ParallaxCard
              title1={t("info_intox_title1")}
              title2={t("info_intox_title2")}
              description={t("info_intox_desc")}
              subdescription={t("info_intox_subdesc")}
              bgImg={require("../../images/cards/Complementation.jpg")}
              color="#669933"
              link="intox"
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="col__container">
            <ParallaxCard
              title1={t("ange_demon_title1")}
              title2={t("ange_demon_title2")}
              description={t("ange_demon_desc")}
              subdescription={t("ange_demon_subdesc")}
              bgImg={require("../../images/cards/Cholesterol.jpg")}
              color="#ff3300"
              link={"cholesterol"}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CardingPage;
